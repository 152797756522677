<template>
  <div>
    <div class="container mx-auto px-4">
      <div class="my-4">
        <h3 class="text-gray-400 text-3xl font-medium">
          {{ $t('profile._') }}
        </h3>
      </div>
      <div class="block md:flex md:space-x-4 bg-gray-800 rounded-lg">
        <div class="w-full md:w-1/3 lg:w-1/4 bg-indigo-600 rounded-lg flex flex-col">
          <div class="flex items-center p-4">
            <div class="w-10 h-10 image-fit">
              <svg
                xmlns="http://www.w3.org/2000/svg" class="w-10 h-10" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              ><path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3" /><circle cx="12" cy="10" r="3" /><circle cx="12" cy="12" r="10" /></svg>
            </div>
            <div class="ml-4 overflow-hidden">
              <div class="font-medium text-lg truncate min-w-0">
                {{ username }}
              </div>
              <div class="text-indigo-200 text-sm truncate min-w-0">
                {{ email }}
              </div>
            </div>
          </div>

          <div class="p-4 border-t border-indigo-700">
            <router-link v-for="tab in tabs" :key="tab.id" :class="['flex items-center mt-4', tab === currentTab ? 'font-medium text-white' : 'text-indigo-100']" :to="{ name: 'Profile', hash: '#'+tab.id }">
              <!-- eslint-disable vue/no-v-html -->
              <svg
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6 mr-3"
                v-html="tab.icon"
              />{{ tab.title }}
              <!-- eslint-enable -->
            </router-link>
          </div>

          <div class="p-5 border-t flex border-indigo-700 mt-auto">
            <router-link :to="{ name: 'Dashboard' }" class="px-2 py-2 rounded bg-transparent border border-gray-100 hover:bg-white hover:text-indigo-500">
              {{ $t('dashboard._') }}
            </router-link>
            <router-link :to="{ name: 'Support' }" class="ml-auto px-2 py-2 rounded bg-transparent border border-gray-100 hover:bg-white hover:text-indigo-500">
              {{ $t('support._') }}
            </router-link>
          </div>
        </div>
        <div class="flex-1">
          <component :is="tabComponent" :origin="currentTab" />
        </div>
      </div>
      <p class="text-xs text-gray-400 text-center mt-2">
        This site is protected by <span class="text-white">reCAPTCHA</span> and the Google <a class="text-white hover:underline" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and <a class="text-white hover:underline" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Terms of Service</a> apply.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data () {
    return {
      tabID: ''
    }
  },
  computed: {
    tabs () {
      return [{
        id: 'information',
        title: this.$t('profile.information'),
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />',
        component: () => import('@/components/ProfileInformation')
      }, {
        id: 'notifications',
        title: this.$t('notifications.__'),
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />',
        component: () => import('@/components/ProfileNotifications')
      }, {
        id: 'password',
        title: this.$t('profile.change_password'),
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />',
        component: () => import('@/components/ProfilePassword')
      }, {
        id: '2fa',
        title: this.$t('profile.2fa'),
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />',
        component: () => import('@/components/Profile2FA')
      }, {
        id: 'verification',
        title: this.$t('profile.verification'),
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />',
        component: () => import('@/components/ProfileVerification')
      }]
    },
    username () {
      return this.$store.state.auth.info && this.$store.state.auth.info.username
    },
    email () {
      return this.$store.state.auth.info && this.$store.state.auth.info.email
    },
    currentTab () {
      if (!this.tabID) {
        return this.tabs[0]
      }

      return this.tabs.find(t => t.id === this.tabID)
    },
    tabComponent () {
      return this.currentTab.component
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (v) {
        if (!v) {
          return
        }

        if (!v.hash) {
          return
        }

        const id = v.hash.substring(1)
        this.tabs.forEach(t => {
          if (t.id === id) {
            this.tabID = id
          }
        })
      }
    }
  }
}
</script>
